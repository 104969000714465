import React from "react";
import Layout from "../components/Layout";
import SEO from '../components/seo';
import { Link } from "gatsby";
import FormContato from '../components/Forms/FormContato';
import OnlyEmail from "../components/Forms/OnlyEmail";
import Interesse from "../components/Forms/Interesse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import TypeWriter from "typewriter-effect";

import Cards from "../components/Cards";

const IndexPage = () => {

  return (
    <div>
        <SEO 
            title='Conheça a Plataforma Shelfin'
            description='Acompanhe e desenvolva as COMPETÊNCIAS e HABILIDADES que seus colaboradores necessitam para potencializar os resultados de sua empresa.'
        />
		<div className="flex items-center justify-center w-full min-h-screen p-10 lg:p-10">
          <div className="mt-20 w-full lg:w-1/2 flex flex-col-reverse lg:flex-row lg:space-x-8 lg:space-y-0 space-y-4">
            <div class="flex flex-col"> 
                <div className="flex flex-col space-y-4 mb-4 text-gray-800  w-full rounded-3xl">
                    <div className="flex flex-col space-y-2">
                        <div className="w-full text-2xl font-bold">👋 Olá, nós somos a Shelfin!</div>
                    </div>
                </div>
                <Interesse destino="PAGE_LINKEDIN" origem="MATRIZ"/>
            </div>
          </div>
		</div>
    </div>
  )
}

export default IndexPage
